import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What do you want to do with your wireframes?"
    options={[
      {
        label: "Redesign them from scratch in a software",
        link: "/app/digital-product-design/hi-fi/design-from-scratch/"
      },
      {
        label: "Just add interactions over pictures of them",
        link: "/app/tool/invision/"
      },
      {
        label: "Scan them into an editable design",
        link: "/app/tool/uizard/"
      }
  ]}/>
)

export default Picker;


